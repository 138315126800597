import React from 'react'
import { Button } from 'react-bootstrap';
import IconStrategy from './../../assets/img/Icon Strategy.png';
import './OurReviews.css';
import CEOImg from './../../assets/img/image17.png';
import '../../App.css';
import { FaArrowRightLong } from "react-icons/fa6";


function OurReviews() {
  return (
    <div className='container-fluid py-5 review-bg'>
        <div className='container'>
            {/* <div className='row'>
                <div className='col-md-6'>
                    <button className='btn-design d-flex align-items-center justify-content-center px-4'>Reviews <FaArrowRightLong className='space-button-icon'/></button>
                </div>
                <div className='col-md-6'>
                    <p>Our software is highly regarded for its seamless performance and user-friendly design. Customers consistently praise its ability to simplify complex tasks and drive results.</p>
                </div>
            </div>

            <div className='row'>
                <div className='col-md-4'>
                    <div className='card review-card p-3 my-2'>
                        <p className='card-text'>"I've been consistently impressed with the quality of service provided by this website. They have exceeded my expectations and delivered exceptional results. Highly recommended!"</p>
                        <div className='d-flex iconstrategy-img'>
                            <img src={IconStrategy} className='me-3'/>
                            <div className=''>
                                <h5>John D.</h5>
                                <p>Company CEO</p>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className='col-md-4'>
                    <div className='card review-card p-3 my-2'>
                        <p className='card-text'>"I've been consistently impressed with the quality of service provided by this website. They have exceeded my expectations and delivered exceptional results. Highly recommended!"</p>
                        <div className='d-flex iconstrategy-img'>
                            <img src={IconStrategy} className='me-3'/>
                            <div className=''>
                                <h5>John D.</h5>
                                <p>Company CEO</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='col-md-4'>
                    <div className='card review-card p-3 my-2'>
                        <p className='card-text'>"I've been consistently impressed with the quality of service provided by this website. They have exceeded my expectations and delivered exceptional results. Highly recommended!"</p>
                        <div className='d-flex iconstrategy-img'>
                            <img src={IconStrategy} className='me-3'/>
                            <div className=''>
                                <h5>John D.</h5>
                                <p>Company CEO</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            {/* ceo part */}

            <div className='row'>
                <div className='col-md-8 col-sm-12 m-auto py-5'>
                    <div className='row '>
                        <div className='col-md-6 d-flex justify-content-center align-items-center'>
                            <img src={CEOImg} className='sm-responsive '/>
                        </div>
                        <div className='col-md-6 align-content-center'>
                            <p>“Be a part of our journey to make life easier for businesses, for communities, and for the world.”</p>
                            <h4>Md Abdur Rahman Tanmoy</h4>
                            <p>CEO, The Second Source</p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
  )
}

export default OurReviews