import React from 'react'
import './Footer.css';
import logoBottom from './../../assets/img/logo.png';
import MainMenu from '../MainMenu/MainMenu';
import TermsPolicy from '../TermPolicy/TermsPolicy';


function Footer() {
  return (
    <div className='container-fluid bg-footer'>
        <div className='container py-5'>
            <div className='row'>
                <div className='col-md-8'>
                    <img src={logoBottom} alt={logoBottom} className='logo-bottom'/>
                    <div className=' '>
                        <MainMenu/>
                    </div>
                </div>
                <div className='col-md-4'>
                    <p className='d-block footer-lower-news-text'>Get the freshest news from us</p>
                    <div className='row'>
                        <div className='col-md-8'>
                            <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"></input>
                        </div>
                        <div className='col-md-4'>
                            <button type="submit" class="btn-design btn-subscribe">Subscribe</button>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
        <hr className='text-white m-0 py-2'/>
        <div className='container'>
            <TermsPolicy/>
        </div>
    </div>
  )
}

export default Footer