import React from 'react';
import LogoRum from './../../assets/img/Logo_Rum-Exchange1.png';
import LogoStubhub from './../../assets/img/stubhub-purple-logo-light-theme 1.png';
import Logotrekntred from './../../assets/img/Logo trekNtread.png';

import './BrandLogoes.css';


const BrandLogoes = () => {
    return (
        // <div className='d-flex justify-content-between p-4'>
        //     <img src={LogoRum} className='img-fluid logoRum'/>
        //     <img src={LogoStubhub} className='img-fluid logoStubhub'/>
        //     <img src={Logotrekntred} className='img-fluid logotrekntred'/>
        //     <img src={LogoRum} className='img-fluid logoRum'/>
        //     <img src={LogoStubhub} className='img-fluid logoStubhub'/>
        //     <img src={Logotrekntred} className='img-fluid logotrekntred'/>
        //     <img src={LogoRum} className='img-fluid logoRum'/>
        //     <img src={LogoStubhub} className='img-fluid logoStubhub'/>
        //     <img src={Logotrekntred} className='img-fluid logotrekntred'/>
        //     <img src={LogoRum} className='img-fluid logoRum'/>
        // </div>
        <div>
            <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
            <div class="carousel-inner">
                {/* <div class="carousel-item active d-flex">
                    <li className='list-unstyled py-2'><img src={LogoRum} class="d-block w-75" alt="..."/></li>
                    <li className='list-unstyled py-2'><img src={LogoStubhub} class="d-block w-75" alt="..."/></li>
                    <li className='list-unstyled py-2'><img src={Logotrekntred} class="d-block w-75" alt="..."/></li>
                    <li className='list-unstyled py-2'><img src={LogoRum} class="d-block w-75" alt="..."/></li>
                    <li className='list-unstyled py-2'><img src={LogoStubhub} class="d-block w-75" alt="..."/></li>
                    <li className='list-unstyled py-2'><img src={Logotrekntred} class="d-block w-75" alt="..."/></li>
                    <li className='list-unstyled py-2'><img src={LogoRum} class="d-block w-75" alt="..."/></li>
                    <li className='list-unstyled py-2'><img src={LogoStubhub} class="d-block w-75" alt="..."/></li>
                    <li className='list-unstyled py-2'><img src={Logotrekntred} class="d-block w-75" alt="..."/></li>
                </div> */}
                <div class="carousel-item d-flex">
                    <li className='list-unstyled py-4 px-2'><img src={LogoRum} class="d-block w-75" alt={LogoRum}/></li>
                    <li className='list-unstyled py-4 px-2'><img src={LogoStubhub} class="d-block w-75" alt={LogoStubhub}/></li>
                    <li className='list-unstyled py-4 px-2'><img src={Logotrekntred} class="d-block w-75" alt={Logotrekntred}/></li>
                    <li className='list-unstyled py-4 px-2'><img src={LogoRum} class="d-block w-75" alt={LogoRum}/></li>
                    <li className='list-unstyled py-4 px-2'><img src={LogoStubhub} class="d-block w-75" alt={LogoStubhub}/></li>
                    <li className='list-unstyled py-4 px-2'><img src={Logotrekntred} class="d-block w-75" alt={Logotrekntred}/></li>
                    <li className='list-unstyled py-4 px-2'><img src={LogoRum} class="d-block w-75" alt={LogoRum}/></li>
                    <li className='list-unstyled py-4 px-2'><img src={LogoStubhub} class="d-block w-75" alt={LogoStubhub}/></li>
                    <li className='list-unstyled py-4 px-2'><img src={Logotrekntred} class="d-block w-75" alt={Logotrekntred}/></li>
                </div>

            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
            </button>
            </div>
        </div>
    );
};

export default BrandLogoes;