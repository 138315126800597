import React from 'react'
import './TermsPolicy.css'


function TermsPolicy() {
  return (
    <div className='container'>
        <div className='row'>
            <div className='col-md-6'>
                <div className='d-flex text-white pb-3'>
                    <a className='text-white text-decoration-none me-2'>Terms and Conditions</a> |
                    <a className='text-white text-decoration-none me-2 ms-2'>Privacy Policy</a> |
                    <a className='text-white text-decoration-none me-2 ms-2'>Accesibility</a> |
                    <a className='text-white text-decoration-none ms-2'>Legal</a>
                </div>
            </div>
            <div className='col-md-6'>
                <div className='text-white pb-3'>
                    <a className='text-white text-decoration-none text-responsive-center me-2'>© TSS {new Date().getFullYear()}. All right reserved</a>
                </div>
            </div>
        </div>
    </div>
  )
}

export default TermsPolicy