import React from 'react'
import { Nav, NavDropdown } from 'react-bootstrap'

function MainMenu() {
  return (
    <div>
        <Nav
            className="me-auto my-2 my-lg-0 text-center"
            style={{ maxHeight: '100px' }}
            navbarScroll
        >
            
            <NavDropdown title="Who We Are" id="navbarScrollingDropdown">
            <NavDropdown.Item href="#action3">Who We Are</NavDropdown.Item>
            <NavDropdown.Item href="#action4">
                Another action
            </NavDropdown.Item>
            
            <NavDropdown.Item href="#action5">
                Something else here
            </NavDropdown.Item>
            </NavDropdown>

            <NavDropdown  className="text-white" title="What We Do" id="navbarScrollingDropdown">
            <NavDropdown.Item href="#action3">What We Do</NavDropdown.Item>
            <NavDropdown.Item href="#action4">
                Another action
            </NavDropdown.Item>
            
            <NavDropdown.Item href="#action5">
                Something else here
            </NavDropdown.Item>
            </NavDropdown>
            
            <NavDropdown title="Our Impact" id="navbarScrollingDropdown">
            <NavDropdown.Item href="#action3">Our Impact</NavDropdown.Item>
            <NavDropdown.Item href="#action4">
                Another action
            </NavDropdown.Item>
            
            <NavDropdown.Item href="#action5">
                Something else here
            </NavDropdown.Item>
            </NavDropdown>

            <Nav.Link href="#action2" className="text-white">Career</Nav.Link>
            <Nav.Link href="#action2" className="text-white">Contact us</Nav.Link>
            
        </Nav>
    </div>
  )
}

export default MainMenu