import React from 'react'
import './OurBrand.css';
import Peoply from './../../assets/img/Logo peoply.png';
import '../../App.css';
import { FaArrowRightLong } from "react-icons/fa6";

function OurBrand() {
  return (
    <div className='container-fluid bg-our-brand'>
        <div className='container py-5'>
            <div className='row'>
                <div className='col-md-6'>
                    <button className='btn-design d-flex align-items-center justify-content-center'>Our Brands <FaArrowRightLong className='space-button-icon'/></button>
                </div>
                <div className='col-md-6'>
                    <p>Our vision is surving a quality product to you such as Software Engineering, Design & UX, and more,  to find where your unique talents can shine and drive impact.</p>
                </div>
            </div>



                <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <div className='row'>
                            <div className='col-md-3'>
                                <div class="card d-flex">
                                    <div className='p-4 m-auto peoply-bg'>
                                        <img src={Peoply} class="card-img-top  justify-content-center align-items-center" alt={Peoply}/>
                                    </div>
                                    <div class="card-body bg-card-peoply-text text-center">
                                        <h5 class="card-title">The Peoply</h5>
                                        <p class="card-text">We make peoply for your help We make peoply for your help</p>
                                    </div>
                                </div>
                            </div>

                            <div className='col-md-3'>
                                <div class="card d-flex">
                                    <div className='p-4 m-auto peoply-bg'>
                                        <img src={Peoply} class="card-img-top  justify-content-center align-items-center" alt={Peoply}/>
                                    </div>
                                    <div class="card-body bg-card-peoply-text text-center">
                                        <h5 class="card-title">The Peoply</h5>
                                        <p class="card-text">We make peoply for your help We make peoply for your help</p>
                                    </div>
                                </div>
                            </div>

                            <div className='col-md-3'>
                                <div class="card d-flex">
                                    <div className='p-4 m-auto peoply-bg'>
                                        <img src={Peoply} class="card-img-top  justify-content-center align-items-center" alt={Peoply}/>
                                    </div>
                                    <div class="card-body bg-card-peoply-text text-center">
                                        <h5 class="card-title">The Peoply</h5>
                                        <p class="card-text">We make peoply for your help We make peoply for your help</p>
                                    </div>
                                </div>
                            </div>

                            <div className='col-md-3'>
                                <div class="card d-flex">
                                    <div className='p-4 m-auto peoply-bg'>
                                        <img src={Peoply} class="card-img-top  justify-content-center align-items-center" alt={Peoply}/>
                                    </div>
                                    <div class="card-body bg-card-peoply-text text-center">
                                        <h5 class="card-title">The Peoply</h5>
                                        <p class="card-text">We make peoply for your help We make peoply for your help</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    
                    <div className="carousel-item">
                        <div className='row'>
                        <div className='col-md-3'>
                                <div class="card d-flex">
                                    <div className='p-4 m-auto peoply-bg'>
                                        <img src={Peoply} class="card-img-top  justify-content-center align-items-center" alt={Peoply}/>
                                    </div>
                                    <div class="card-body bg-card-peoply-text text-center">
                                        <h5 class="card-title">The Peoply</h5>
                                        <p class="card-text">We make peoply for your help We make peoply for your help</p>
                                    </div>
                                </div>
                            </div>

                            <div className='col-md-3'>
                                <div class="card d-flex">
                                    <div className='p-4 m-auto peoply-bg'>
                                        <img src={Peoply} class="card-img-top  justify-content-center align-items-center" alt={Peoply}/>
                                    </div>
                                    <div class="card-body bg-card-peoply-text text-center">
                                        <h5 class="card-title">The Peoply</h5>
                                        <p class="card-text">We make peoply for your help We make peoply for your help</p>
                                    </div>
                                </div>
                            </div>

                            <div className='col-md-3'>
                                <div class="card d-flex">
                                    <div className='p-4 m-auto peoply-bg'>
                                        <img src={Peoply} class="card-img-top  justify-content-center align-items-center" alt={Peoply}/>
                                    </div>
                                    <div class="card-body bg-card-peoply-text text-center">
                                        <h5 class="card-title">The Peoply</h5>
                                        <p class="card-text">We make peoply for your help We make peoply for your help</p>
                                    </div>
                                </div>
                            </div>

                            <div className='col-md-3'>
                                <div class="card d-flex">
                                    <div className='p-4 m-auto peoply-bg'>
                                        <img src={Peoply} class="card-img-top  justify-content-center align-items-center" alt={Peoply}/>
                                    </div>
                                    <div class="card-body bg-card-peoply-text text-center">
                                        <h5 class="card-title">The Peoply</h5>
                                        <p class="card-text">We make peoply for your help We make peoply for your help</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
                </div>

        </div>
    </div>
  )
}

export default OurBrand